<template>
  <div
    :class="{
      'app-hidden': commonStore.loader || commonStore.hidden,
    }"
  >
    <loader v-if="commonStore.loader" />
    <router-view :key="$route.fullPath" />
    <AppBaseDialog v-if="commonStore.dialogParams.open" />
    <!-- <create-new-offer /> -->
  </div>
</template>

<script>
import { defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useAuthStore } from "./store/auth.store";
import { useCommonStore } from "@/store/common.store";
import vArtNearApi from "@v-art-protocol/v-art-protocol-near-sdk";

import Loader from "./components/BaseLoader.vue";
import AppBaseDialog from "./components/BaseDialog.vue";
// import CreateNewOffer from "./pages/minter/CreateNewOffer.vue";

export default defineComponent({
  components: { Loader, AppBaseDialog },

  mounted() {
    window.addEventListener("resize", () => {
      this.commonStore.$patch({ windowWidth: window.innerWidth });
    });
  },

  unmounted() {
    window.removeEventListener("resize");
  },

  setup() {
    const router = useRouter();
    const authStore = useAuthStore();
    const commonStore = useCommonStore();

    onMounted(async () => {
      commonStore.$patch({ windowWidth: window.innerWidth });

      try {
        await vArtNearApi.initContract(
          "valexa-license.testnet",
          "nft.valexa-license.testnet",
          "market.valexa-license.testnet",
          "license.valexa-license.testnet",
          "testnet",
          "https://licensing-testnet.v-art.digital:3100"
        );

        await vArtNearApi.initGraph(
          "https://api.thegraph.com/subgraphs/name/valexandrovich/valexa-license"
        );

        await vArtNearApi.initIpfs(
          "544fa186c3fd4aa39e8e",
          "7f1d4747551777aa7428a6cf29a0a6e582e827db7bbb3eed1e8aced8d18cc82d",
          "https://vart.mypinata.cloud/ipfs/"
        );

        let account = vArtNearApi.getAccount();

        if (account) authStore.$patch({ user: account });
        else authStore.$patch({ user: "" });
      } catch (error) {
        console.log("Initialization error: ", error);
      }
    });

    authStore.$subscribe((_, state) => {
      if (!state.user) {
        router.push({ name: "auth" });
      }
    });

    return { commonStore };
  },
});
</script>

<style>
* {
  margin: 0;
  padding: 0;
  /* font-family: "Stolzl"; */
}

.app-hidden {
  overflow: hidden;
  height: 100vh;
}
</style>
