import { defineStore } from 'pinia'

export const useAuthStore = defineStore({
  id: 'Auth',
  state: () => ({
    user: '',
  }),
  getters: {
    isAuthenticated: state => state.user
  },
  actions: {
    async bindUser() {
      
    }
  }
})
