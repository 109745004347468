<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="170"
    height="30"
    viewBox="0 0 170 30"
    fill="none"
    v-if="name == 'logo'"
  >
    <g clip-path="url(#clip0_305_7671)">
      <path
        d="M48.7988 17.4338C46.8592 17.4338 45.2885 19.0079 45.2885 20.9516C45.2885 22.8953 46.8592 24.4694 48.7988 24.4694C50.7383 24.4694 52.309 22.8953 52.309 20.9516C52.309 19.0079 50.7383 17.4338 48.7988 17.4338Z"
        fill="#0000FF"
      />
      <path
        d="M166.311 3.4589e-06H136.951C135.439 3.4589e-06 134.214 1.22824 134.214 2.74267C134.214 4.2571 135.439 5.48534 136.951 5.48534H148.895V24.4932C137.424 24.3573 123.04 23.3127 114.404 19.43C113.119 18.8529 112.119 18.2805 111.341 17.7486H121.796C126.679 17.7486 130.651 13.7682 130.651 8.87432C130.651 3.98045 126.677 3.4589e-06 121.793 3.4589e-06H93.5684L73.8847 24.0019C73.5182 24.4479 73.0494 24.5147 72.8019 24.5147C72.5544 24.5147 72.0855 24.4479 71.719 24.0019L54.1106 2.52803C52.7993 0.930125 50.8645 0.014313 48.7987 0.014313C46.733 0.014313 44.7982 0.930125 43.4869 2.52803L25.8784 24.0043C25.512 24.4503 25.0431 24.5171 24.7956 24.5171C24.5481 24.5171 24.0793 24.4503 23.7128 24.0043L4.85251 1.00167C3.89105 -0.169326 2.16804 -0.338656 0.999538 0.624854C-0.168966 1.58598 -0.337935 3.31505 0.623523 4.48367L19.4838 27.4863C20.7951 29.0842 22.7299 30.0024 24.7956 30.0024C26.8589 30.0024 28.7961 29.0866 30.1074 27.4887L47.7183 6.0124C48.0848 5.56642 48.5536 5.49965 48.8011 5.49965C49.0486 5.49965 49.5175 5.56642 49.884 6.0124L67.4948 27.4863C68.8061 29.0842 70.7409 30 72.8066 30C74.8723 30 76.8072 29.0842 78.1185 27.4839L96.1577 5.48534H121.796C123.662 5.48534 125.178 7.00453 125.178 8.87432C125.178 10.7441 123.662 12.2633 121.796 12.2633H103.197V15.006C103.197 15.7906 103.542 19.8951 110.356 23.5488C118.338 27.8273 132.224 29.9952 151.632 29.9952H154.369V5.48534H166.313C167.824 5.48534 169.05 4.2571 169.05 2.74267C169.05 1.22824 167.822 3.4589e-06 166.311 3.4589e-06Z"
        fill="#0000FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_305_7671">
        <rect width="169.048" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>

  <svg
    v-if="name == 'near-logo'"
    xmlns="http://www.w3.org/2000/svg"
    width="76"
    height="20"
    viewBox="0 0 76 20"
    fill="none"
  >
    <path
      d="M38.0449 4.17778V15.8444C38.0449 15.9333 37.9783 16.0222 37.8672 16.0222H36.6449C36.0894 16.0222 35.5561 15.7333 35.2449 15.2667L29.7338 6.75556L29.9116 11V15.8667C29.9116 15.9556 29.8449 16.0444 29.7338 16.0444H28.1338C28.0449 16.0444 27.9561 15.9778 27.9561 15.8667V4.17778C27.9561 4.08889 28.0227 4 28.1338 4H29.3338C29.8894 4 30.4227 4.28889 30.7338 4.75556L36.2449 13.2444L36.0672 9V4.17778C36.0672 4.08889 36.1338 4 36.2449 4H37.8449C37.9783 4 38.0449 4.06667 38.0449 4.17778Z"
      fill="#292839"
    />
    <path
      d="M54.444 16H52.7551C52.644 16 52.5551 15.8889 52.5996 15.7778L57.0885 4.15556C57.1329 4.06667 57.2218 4 57.3107 4H59.444C59.5551 4 59.644 4.06667 59.6663 4.15556L64.1774 15.7778C64.2218 15.8889 64.1329 16 64.0218 16H62.3329C62.2663 16 62.1996 15.9556 62.1774 15.8889L58.5551 6.33333C58.5107 6.2 58.2885 6.2 58.244 6.33333L54.6218 15.8889C54.5774 15.9556 54.5107 16 54.444 16Z"
      fill="#292839"
    />
    <path
      d="M75.9557 15.7333L72.5779 11.4222C74.489 11.0667 75.6001 9.77778 75.6001 7.8C75.6001 5.53333 74.1334 4 71.5112 4H66.8001C66.6668 4 66.5557 4.11111 66.5557 4.24444C66.5557 5.13333 67.2668 5.84444 68.1557 5.84444H71.3334C72.9112 5.84444 73.6668 6.64444 73.6668 7.82222C73.6668 9 72.9334 9.8 71.3334 9.8H66.8445C66.7112 9.8 66.6001 9.91111 66.6001 10.0444V15.8222C66.6001 15.9111 66.6668 16 66.7779 16H68.3779C68.4668 16 68.5557 15.9333 68.5557 15.8222V11.5333H70.4001L73.3334 15.3556C73.6445 15.7778 74.1334 16 74.6668 16H75.889C75.9779 16 76.0445 15.8444 75.9557 15.7333Z"
      fill="#292839"
    />
    <path
      d="M49.3776 4H41.9332C41.7998 4 41.7109 4.08889 41.7109 4.22222C41.7109 5.11111 42.4443 5.84444 43.3332 5.84444H49.3776C49.4665 5.84444 49.5554 5.77778 49.5554 5.66667V4.15556C49.5332 4.06667 49.4665 4 49.3776 4ZM49.3776 14.1556H43.8221C43.7332 14.1556 43.6443 14.0889 43.6443 13.9778V10.9778C43.6443 10.8889 43.7109 10.8 43.8221 10.8H48.9554C49.0443 10.8 49.1332 10.7333 49.1332 10.6222V9.11111C49.1332 9.02222 49.0665 8.93333 48.9554 8.93333H41.9554C41.8221 8.93333 41.7109 9.04444 41.7109 9.17778V15.7333C41.7109 15.8667 41.8221 15.9778 41.9554 15.9778H49.3776C49.4665 15.9778 49.5554 15.9111 49.5554 15.8V14.2889C49.5332 14.2222 49.4665 14.1556 49.3776 14.1556Z"
      fill="#292839"
    />
    <path
      d="M16.0444 1.02222L11.8667 7.22222C11.5778 7.64444 12.1333 8.15556 12.5333 7.8L16.6444 4.22222C16.7556 4.13333 16.9111 4.2 16.9111 4.35556V15.5333C16.9111 15.6889 16.7111 15.7556 16.6222 15.6444L4.17778 0.755556C3.77778 0.266667 3.2 0 2.55556 0H2.11111C0.955556 0 0 0.955556 0 2.13333V17.8667C0 19.0444 0.955556 20 2.13333 20C2.86667 20 3.55556 19.6222 3.95556 18.9778L8.13333 12.7778C8.42222 12.3556 7.86667 11.8444 7.46667 12.2L3.35556 15.7556C3.24444 15.8444 3.08889 15.7778 3.08889 15.6222V4.46667C3.08889 4.31111 3.28889 4.24444 3.37778 4.35556L15.8222 19.2444C16.2222 19.7333 16.8222 20 17.4444 20H17.8889C19.0667 20 20.0222 19.0444 20.0222 17.8667V2.13333C20 0.955556 19.0444 0 17.8667 0C17.1333 0 16.4444 0.377778 16.0444 1.02222Z"
      fill="#292839"
    />
  </svg>

  <svg
    v-if="name == 'arrow-right'"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8223 4.44751L15.3748 9.00001L10.8223 13.5525"
      stroke="#0000FF"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.625 9H15.2475"
      :stroke="'#0000FF' || color"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

  <svg
    v-if="name == 'plus'"
    :fill="'#0000FF' || color"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="16px"
    height="16px"
    fill-rule="evenodd"
  >
    <path
      fill-rule="evenodd"
      d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"
    />
  </svg>
  <svg
    v-if="name == 'near-icon'"
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      d="M9.38997 0.949999L7.04262 4.4375C6.88029 4.675 7.19241 4.9625 7.41717 4.7625L9.72709 2.75C9.78958 2.7 9.87695 2.7375 9.87695 2.825V9.11248C9.87695 9.20001 9.76457 9.23752 9.71462 9.17497L2.7224 0.8C2.49765 0.525 2.173 0.375 1.81091 0.375H1.56118C0.911904 0.375 0.375 0.9125 0.375 1.575V10.425C0.375 11.0875 0.911904 11.625 1.57367 11.625C1.98571 11.625 2.37279 11.4125 2.59754 11.05L4.94493 7.56251C5.10725 7.32502 4.7951 7.03748 4.57035 7.2375L2.26041 9.23752C2.19797 9.28747 2.11057 9.25001 2.11057 9.16249V2.8875C2.11057 2.8 2.22295 2.7625 2.27289 2.825L9.26512 11.2C9.48987 11.475 9.827 11.625 10.1766 11.625H10.4264C11.0881 11.625 11.625 11.0875 11.625 10.425V1.575C11.6125 0.9125 11.0756 0.375 10.4139 0.375C10.0018 0.375 9.61472 0.5875 9.38997 0.949999Z"
      fill="#8D9AAD"
    />
  </svg>
</template>

<script>
  export default {
    name: "BaseIcon",

    props: {
      name: {
        type: String,
      },
      color: {
        type: String,
      },
    },
  };
</script>

<style lang="scss" scoped></style>
